import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formio, Form } from '@formio/react';
import './Forms.scss';
import {
    Link,
} from 'react-router-dom';
import { Divider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import moment from "moment";
import { useNavigate } from "react-router-dom";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export function FormDataView() {
    const [formData, setValue] = useState({});
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [path, setPath] = React.useState('');
    const [Id, setId] = React.useState(0);
    const [coverKey, setCoverkey] = React.useState(0);
    const [viewMode, setViewMode] = React.useState(0);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [coverLogoUrl, setCoverLogoUrl] = useState('')
    const [locKey, setLocKey] = React.useState(0);
    const navigate = useNavigate();

    const _dataSubmitted = async (componentData) => {
        try {
            var data = componentData.data
            const params = new URLSearchParams(window.location.search)
            // Remove Timezone from all the Date/Time Components
            var patientName = ""
            var patientAppointment = ""
            var myRegExp = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})\+\d{2}:\d{2}$/;
            Object.entries(data).map(([tkey, tvalue]) => {
                if (typeof tvalue !== 'object') {
                    try {
                        if (tvalue.match(myRegExp)) {
                            var newformat = moment(tvalue).format("MM/DD/YYYY HH:mm:ss")
                            data[tkey] = newformat
                        }
                    }
                    catch (ex) {
                    }
                }
            })
            if (data.lastname !== null || data.lastname !== undefined)
                patientName = data.lastname
            if ((data.firstname !== null || data.firstname !== undefined) && patientName !== "")
                patientName += ", " + data.firstname
            else
                patientName = data.firstname

            if (data.appointmentdate !== null)
                patientAppointment = data.appointmentdate
            data.locKey = locKey;

            let url = `${process.env.REACT_APP_FORMS_API}/postFormData?Id=${Id}&coverKey=${coverKey}`;
            let res = await axios.post(url, data);
            if (res?.data) {

                url = `${process.env.REACT_APP_FORMS_API}/getECPInformation?coverKey=${localStorage.getItem('coverKey')}&locKey=${localStorage.getItem('locKey')}`;
                const resECP = await axios.get(url);   
                 if (resECP?.data) {
                    var d = resECP.data;  
                    var request ={
                        "CustomerId" : Id,
                       "CoverKey": coverKey,
                       "FormName": name,
                       "ECPEmail": d.Email,
                       "OrgName": d.OrgName,
                       "PatientName": patientName,
                       "AppointmentDate" : patientAppointment
                   }    
                   
                   url = `${process.env.REACT_APP_FORMS_API}/postRegFormSubmissionEmail`;
                   const resNotif = await axios.post(url,request);   
                    if (resNotif?.data) {
                        window.location = '/thankyou';
                        // setOpenSnackbar(true);
                    }
                }
                //setOpenSnackbar(true);
            }
            else {

            }
        } catch (e) {
            let message = 'Unexpected error in saving schema.';
        }

    }

    const _submitDone = (data) => {
        console.log("Done");
    }

    const isValidCoverLocKey = async (coverKey, locKey) => {
        try {

            const response = await axios.get(
                `${process.env.REACT_APP_FORMS_API}/getECPInformation?coverKey=${coverKey}&locKey=${locKey}`
            );
            return !!response?.data?.OrgName;
        } catch (error) {
            return false;
        }
    };

    useEffect(async () => {
        if (!loading) {
            // Update the document title using the browser API
            const params = new URLSearchParams(window.location.search)
            let pn = params.get('pn')
            let cK = params.get('coverKey')
            let lK = (params.get('locKey') == null || params.get('locKey') === '') ? 0 : params.get('locKey')
            let vm = (params.get('viewMode') == null || params.get('viewMode') === '') ? 0 : params.get('viewMode')

            if (cK !== null)
                localStorage.setItem('coverKey', cK);
            if (lK !== null)
                localStorage.setItem('locKey', lK);
            else
                localStorage.setItem('locKey', 0);

            setCoverkey(cK)
            setViewMode(vm)
            setLocKey(lK);
            // console.log("locKey:" +lK);
            // console.log("viewMode:" + vm);

            // Route to "404 Nothing Found" for invalid locations
            if (vm !== "1") {
                if (!(await isValidCoverLocKey(cK, lK))) {
                    setLoading(true);
                    navigate("/404");
                }
                // console.log('updatemode')
            }
            else {
                // console.log('viewmode')
            }


            try {
                const urlGetLogo = `${process.env.REACT_APP_FORMS_API}/getCoverKeyLogo?coverKey=${cK}`;
                const res = await axios.get(urlGetLogo);
                if (res?.data) {
                    var arrLogoUrl = res.data;
                    if (arrLogoUrl.length > 0)
                        setCoverLogoUrl(arrLogoUrl[0])
                    else
                        setCoverLogoUrl('')
                }
                else
                    setCoverLogoUrl('')
            }
            catch (error) {
                setCoverLogoUrl('')
            }
            // setId(Id);
            try {
                const url = `${process.env.REACT_APP_FORMS_API}/getFormDataByPathId?Id=${pn}&coverKey=${cK}`;
                axios.get(url).then((res) => {
                    if (res?.data) {
                        var d = res.data;
                        setValue(JSON.parse(d.Data));
                        setName(d.Name)
                        setPath(d.PathName)
                        setDescription(d.Description)
                        setId(d.Id);
                        var formComponent = JSON.parse(d.Data)

                        if (viewMode == 0) {
                            Formio.icons = 'fontawesome';
                            Formio.createForm(document.getElementById("formio-result"), {
                                components: formComponent.components,
                            },).then((form) => {
                                // Prevent the submission from going to the form.io server.
                                form.nosubmit = true;
                                form.on("submit", (data) => {
                                    _dataSubmitted(data);
                                    form.emit("submitDone");
                                }
                                )
                                form.on('submitDone', () => {
                                    //  window.location = '/thankyou';
                                    _submitDone();
                                    //form.emit('resetForm');
                                });
                            })
                            setLoading(true)
                        }
                        else {
                            Formio.createForm(document.getElementById("formio-result"), {
                                components: formComponent.components,
                            }, {
                                readOnly: true,
                                hide: {
                                    submit: true
                                }
                            }).then((form) => {
                                // Prevent the submission from going to the form.io server.
                                form.nosubmit = true;

                                form.on("submit", (data) => {
                                    _dataSubmitted(data);
                                    form.emit("submitDone");
                                }
                                )

                                form.on('submitDone', () => {
                                    _submitDone();
                                    form.emit('resetForm');
                                });



                            })
                            setLoading(true)
                        }

                    }
                    else {
                        setLoading(true)
                        navigate("/404");
                    }
                }).catch((ex) => {
                    let e = "error on this page"
                    setLoading(true)
                    navigate("/404");
                })

            }
            catch (error) {
                let e = "error on this page"
                setLoading(true)
                navigate("/404");
            }
        }
    }, [Id])



    return (
        <>
            <div className='formadd-main'>
                {coverLogoUrl !== "" && <div style={{ paddingTop: "10px", backgroundColor: "#F1F1F1" }}>
                    <table border="0" align="center" cellpadding="0" cellspacing="0" >
                        <tr valign=" middle">
                            <td>
                                <img width="100%" height="70px" alt="4PatientCare" src={coverLogoUrl} border="1" />
                            </td>
                        </tr>
                    </table>
                    <hr />
                </div>}
                <div className='formadd-preview'>
                    <div className='formadd-content'>
                        <div id="formio-result" />
                    </div>
                </div>
            </div>
            <div>
                <Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center', }} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
                    <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                        Form saved successfully.
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}
