import React from 'react'

const Health = () => {

    console.log('Test');
  return (
    <div>
        <h1>Health</h1></div>
  )
}

export default Health