import "./App.css";
import React, { useState, useEffect } from "react";
import { FormDataView } from "./Pages/Forms";
import { ThankYou } from "./Pages/Submission/Thankyou";
import { PageNotFound } from "./Pages/401/401";
import { Routes, Route } from "react-router-dom";
import { NothingFound } from "./Pages/404/404";
import Health from "./Pages/Health/Health";

function App() {

  return (
    <div className="App">
        <Routes>
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/displayform" element={<FormDataView />} />
          <Route path="/404" element={<NothingFound />} />
          <Route path="/health" element={<Health />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
    </div>
  );
}

export default App;
