/**
 * Represents a collection of strongly typed constants.
 */
const Constants = {
    get headerKey() {
        return 'x-frm-pub';
    },
    get headerValue() {
        return '9287eacd-2b80-468d-b042-5ae9576b3942';
    }
};

// Lock object to prevent modification (true static).
Object.freeze(Constants);

export default Constants;