import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Thankyou.scss";

export function ThankYou() {
  const [orgName, setOrgName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [telNum, setTelNum] = React.useState('');
/*   useEffect(async ()  => {
    var coverKey = localStorage.getItem('coverKey');
    var locKey = localStorage.getItem('locKey');
    const url = `${process.env.REACT_APP_FORMS_API}/getECPInformation?coverKey=${coverKey}&locKey=${locKey}`;
    const res = await axios.get(url);   
     if (res?.data) {
        var d = res.data;  
        setOrgName(d[0].OrgName)
        setAddress(d[0].Address1)
        setTelNum(d[0].FrontLine)
     }
   }, [])   */   

    return (
        <>
            <header class="site-header" id="header">
              <h1 class="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
            </header>

            <div class="main-content">
              <i class="fa fa-check main-content__checkmark" id="checkmark"></i>
              <p class="main-content__body" data-lead-id="main-content-body">Thank you for completing the form. We look forward to seeing you soon!</p>
            </div>

            <footer class="site-footer" id="footer">
{/*               <div class="site-footer__fineprint" id="fineprint">Copyright ©2022 {orgName} -  All Rights Reserved - {address} Tel # {telNum}</div>
 */}
            </footer>        
        </>
    )
}