import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';

export function PageNotFound() {
    return (
        <>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: 'white',
            }}
            >
            <Typography variant="h1" style={{ color: 'gray' }}>
                401
            </Typography>
            <Typography variant="h6" style={{ color: 'gray' }}>
                Unauthorized: Access is denied due to invalid resources
            </Typography>
            </Box>
        </>
        )
}